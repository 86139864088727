import About from "../components/HeroSection/About";
import Header from "../components/HeroSection/Header";
import LogoElipse from "../assets/imgs/logo-elipse.png";
import CarbonEcosystem from "../components/CarbonEcosystem";
import Elipse5 from "../assets/imgs/Ellipse 5.png";
import Elipse6 from "../assets/imgs/Ellipse 6.png";
import HeroGreen from "../assets/imgs/hero-green.png";
import CrabonCredit from "../components/CrabonCredit";
import CarbonCreditEcosystem from "../components/CarbonCreditEcosystem";
import Tokenomics from "../components/Tokenomics";
import OurContract from "../components/OurContract";
import Benefits from "../components/Benefits";
import MoreInfo from "../components/MoreInfo";
import LegalDesclaimer from "../components/LegalDesclaimer";
import Elipse10 from "../assets/imgs/Ellipse 6.png";
import Elipse16 from "../assets/imgs/Ellipse 7.png";
import Elipse17 from "../assets/imgs/Ellipse 17.png";
import Elipse18 from "../assets/imgs/Ellipse 7.png";
import Elipse19 from "../assets/imgs/Ellipse 17.png";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
const Landing = (props) => {
  const aboutRef = useRef(null);
  const tokenRef = useRef(null);
  const stakingRef = useRef(null);
  const topRef = useRef(null);
  const topScroll = () => topRef.current.scrollIntoView();
  const aboutScroll = () => aboutRef.current.scrollIntoView();
  const tokenScroll = () => tokenRef.current.scrollIntoView();
  const stakingScroll = () => stakingRef.current.scrollIntoView();

  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollValue]);
  return (
    <div className="position-relative">
      {scrollValue > 300 && (
        <Button className="btn-top" onClick={topScroll}>
          <i className="fa fa-arrow-up"></i>
        </Button>
      )}
      <div className="hero position-relative" ref={topRef}>
        <img
          src={LogoElipse}
          alt="elipse"
          className="hero__logo-elipse img-fluid"
        />
        <Header
          aboutScroll={aboutScroll}
          tokenScroll={tokenScroll}
          stakingScroll={stakingScroll}
        />
        <img src={HeroGreen} alt="" className="img-fluid hero-green" />
        <div ref={aboutRef}>
          <About />
        </div>
        <img src={Elipse5} alt="" width="100%" className="elipse5 img-fluid" />
      </div>
      {/* <div className="position-relative carbon_ecosystem">
        <img src={Elipse6} alt="" className="img-fluid elipse6" />
        <CarbonEcosystem />
      </div>
      <CrabonCredit />
      <div className="position-relative">
        <CarbonCreditEcosystem />
        <img src={Elipse10} alt="" className="img-fluid elipse10" />
      </div>
      <div className="position-relative" ref={tokenRef}>
        <img src={Elipse16} alt="" className="img-fluid elipse16" />
        <Tokenomics />
        <img src={Elipse17} alt="" className="img-fluid elipse17" />
      </div>
      <OurContract />
      <div className="position-relative">
        <img src={Elipse18} alt="" className="img-fluid elipse18" />
        <Benefits />
        <img src={Elipse19} alt="" className="img-fluid elipse19" />
      </div>
      <div className="position-relative" ref={stakingRef}>
        <img src={Elipse5} alt="" className="img-fluid elipse22 w-100" />
        <MoreInfo />
      </div>{" "}
      <div className="position-relative">
        <LegalDesclaimer />
      </div> */}
    </div>
  );
};

export default Landing;
