import React from "react";
import { Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import img1 from "../../assets/imgs/photo_2023-08-11_17-41-31-removebg-preview.png";
import img2 from "../../assets/imgs/photo_2023-08-11_17-42-23-removebg-preview.png";
import img3 from "../../assets/imgs/photo_2023-08-11_17-28-54-removebg-preview.png";
function About() {
  const history = useHistory();
  return (
    <div className="hero__about mt-5 mt-md-10">
      <Container>
        <h1 className="text-center hero__about-title hero-heading">
          Modern Meme Warfare
        </h1>
        <div className="position-relative hero__about__form-container mx-auto mt-5">
          {/* <Input
						type='email'
						placeholder='Enter your email'
						className='hero__about__form-container-form-control'
					/>
					<Button className='btn__secondary hero__about__form-container-btn'>
						Subscribe
					</Button> */}
        </div>
        <div className="header-imgs-section">
          <div className="container">
            <div className="row image-container">
              <div className="col-12 col-md-4">
                <div className="contain-imgs">
                  <img className="img-2" src={img2} width={300} alt="" />
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="contain-imgs contain-imgs-2">
                  <img className="img-2" src={img1} width={400} alt="" />
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="contain-imgs">
                  <img className="img-3" src={img3} width={300} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mt-md-10 text-center">
          <h5 className="hero__about__heading">Staking Details </h5>
          <p className="mt-5 hero__about__desc staking-det">
            APY = 500%
            <br />
            No lock period
            <br />
            No withdrawal fees
            <br />
            If unstaking within 72 hours from stake no rewards earnt. But NO
            early withdrawal fee
            <br />
            After 72 hours, users who unstake will receive initial staked tokens
            PLUS rewards
          </p>
          <Button
            className="btn__primary mt-5"
            onClick={() => history.push("/staking")}
          >
            Stake Now
            <i className="fa fa-arrow-right ml-3"></i>
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default About;
