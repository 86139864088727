import Landing from "../views/Landing";
import Staking from "../views/Staking";

let routes = [
  {
    path: "/",
    component: Landing,
    layout: "main",
  },
  // {
  //   path: "/presale",
  //   component: Presale,
  //   layout: "dashboard",
  // },
  {
    path: "/staking",
    component: Staking,
    layout: "dashboard",
  },
  // {
  //   path: "/rewards",
  //   component: Reward,
  //   layout: "dashboard",
  // },
];
export default routes;
